<template>
  <div class="dropdown-container" :class="{active: show}" v-click-outside="() => toggle(false)">
    <p v-if="label" @click="toggle()" class="dropdown-label">{{ label }}</p>
    <div class="dropdown-wrapper">
      <button @click="toggle()" :disabled="disabled" class="dropdown-btn" :class="{empty: !active}" type="button">
        {{ active ? active.name : placeholder }}
        <Arrow v-if="!disabled"/>
      </button>
      <simplebar v-if="!disabled && list && list.length > 0" class="dropdown-content">
        <ul class="dropdown-list">
          <li v-if="withEmpty && active" @click="select(null)" class="dropdown-list-item">Unselect</li>
          <li @click="select(item)" :class="{active: check(item)}" class="dropdown-list-item"
              v-for="(item, idx) in list" :key="`dropdown-${idx}`">
            {{ item.name }}
          </li>
        </ul>
      </simplebar>
    </div>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';
import Arrow from '@/assets/icons/arrow-down.svg?inline'

export default {
  name: "ADropdown",
  props: {
    label: String,
    active: Object,
    list: Array,
    withEmpty: Boolean,
    disabled: Boolean,
    placeholder: {
      type: String,
      default: () => 'Select...'
    }
  },
  components: {
    Arrow
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    select(data) {
      this.$emit('selected', this.active && data && data.value === this.active.value ? null : data);
      this.toggle(false);
    },
    toggle(bool = null) {
      this.show = bool === null ? !this.show : bool;
    },
    check(item) {
      return this.active ? this.active.value === item.value : false;
    }
  }
}
</script>
